<template>
    <div>
        <div>
        <div class="container-fluid">

        <!-- Page Heading -->
            <div class="d-sm-flex align-items-center justify-content-between mb-4" style="padding-top:20px">
                <h1 class="h3 mb-0 text-gray-800">Weekly Banner</h1>
            </div>
            <p class="mb-4">Manage Weekly Banners. </p>
            <div class="search">
            
                <form style="padding-top:30px">
                <div class="form-row">
                    <div class="form-group col-md-12">
                        <label for="inputEmail4">Username <span>*</span></label>
                        <input type="text" name="name" v-validate="'required'" v-model="objData.username" class="form-control" id="inputEmail4" placeholder="">
                        <span v-show="errors.has('name')" class="help is-danger"><i class="fa fa-warning"></i>{{ errors.first('name') }}</span>
                    </div>
                </div>
                <div class="avatar-upload">
                    <div class="avatar-edit">
                        <input type='file' id="imageUpload" @change="onFileChange" accept=".png, .jpg, .jpeg" />
                        <label for="imageUpload"></label>
                    </div>
                    <div class="avatar-preview">
                        <img :src="url" alt="" srcset="">
                        
                    </div>
                </div>
                <div class="form-group">
                    <button class="btn btn-outline-primary" @click.prevent="saveBanner"> Save </button>
                </div>
                               
                       
                </form>
        
            </div>
        </div>
        </div>
    </div>
</template>

<script>
    import Crud from '../../services/main/CRUD.js'
    export default {
        data() {
            return {
                objData: {
                    username: "",
                    user_id: "",
                    image: "",
                },
                url: null
            }
        },
         methods: {
            onFileChange(e) {
                const file = e.target.files[0];
                this.url = URL.createObjectURL(file);
                var fileReader = new FileReader()
                fileReader.readAsDataURL(file)
                fileReader.onload = (e) => {
                    this.objData.image = e.target.result
                    console.log(e.target.result)
                }
            },
            saveBanner() {
                 this.$validator.validateAll().then(()=>{
                    if(!this.errors.any()) {
                        this.$store.dispatch('setLoading',true)
                        Crud.add('admin/weekly-banner-minuz',this.objData)
                            .then((res) => {
                                console.log(res)
                                //this.$store.dispatch('success',"Category Added")
                                this.$store.dispatch('setLoading',false)
                                this.show = true
                                this.$store.dispatch('success',`Weekly Photo added Added`)
                                //this.$router.go(-1)
                                
                            })
                            .catch((err) => {
                                console.log(err)
                                this.$store.dispatch('reqError',err)
                                this.$store.dispatch('setLoading',false)
                            })
                    }
                })
                console.log(this.product)
            }

          }
    }
</script>

<style lang="scss" scoped>

</style>